<template>
  <div class="pages">
    <div class="inside-nav">
      <el-tree
        :data="treeData"
        :props="defaultProps"
        :highlight-current="true"
        accordion
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>

    <div class="task_box">
      <!-- 顶部按钮 -->
      <div class="oper_box">
        <div class="btns" @click="handlePopups()">
          <div class="el-icon el-icon-circle-plus-outline"></div>
          <div>添加设备</div>
        </div>
        <div class="btns" @click="operDerive">
          <div class="el-icon el-icon-upload2"></div>
          <div>导&nbsp;出</div>
        </div>
      </div>
      <!-- select 筛选 -->
      <div class="select_box">
        <div class="s_item">
          <div class="s_lable">设备名称/编号：</div>
          <div class="s_input">
            <el-input v-model="queryInfo.name"></el-input>
          </div>
        </div>
        <div class="s_item">
          <div class="s_lable">设备类型：</div>
          <el-select v-model="queryInfo.type" placeholder="请选择设备类型">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="btns" @click="getFiltrateList">查询</div>
      </div>
      <!-- 数据表格 -->
      <div class="table_boxs">
        <el-table
          ref="multipleTable"
          :data="tableList"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
        >
          <el-table-column label="序号" align="center" width="80">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="name"
            label="设备名称"
            width="140"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="type_text"
            label="设备类型"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="equip_no"
            label="设备编号"
            min-width="120"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="status_text"
            label="设备状态"
            width="90"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="location.address"
            label="设备位置"
            min-width="150"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="electricity_meter_id"
            label="电表ID"
            width="120"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="description"
            label="描述"
            min-width="150"
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            width="130"
          >
            <template slot-scope="scope">
              <div class="handle_box">
                <div
                  class="handle_btn edit"
                  @click="handlePopups(scope.row.id)"
                >
                  编辑
                </div>
                <div class="handle_btn" @click="handleDelete(scope.row.id)">
                  删除
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="queryInfo.page"
          @current-change="handleCurrentChange"
          next-text="下一页"
          prev-text="上一页"
        ></el-pagination>
      </div>
    </div>

    <!-- 添加设备弹出层 -->
    <el-dialog
      :title="addPopupsTitle"
      :visible.sync="dialogFormVisible"
      width="500px"
    >
      <el-form :model="addInfo">
        <el-form-item label="所属项目:" label-width="100px">
          <el-cascader
            :options="organizeList"
            :props="{ checkStrictly: true, label: 'title' }"
            @change="getProjectVal"
            v-model="addInfo.organize_id"
            clearable
          ></el-cascader>
        </el-form-item>

        <el-form-item label="用电类型:" label-width="100px">
          <el-select v-model="addInfo.type" placeholder="请选择活动区域">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称:" label-width="100px">
          <el-input v-model="addInfo.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="安装位置:" label-width="100px">
          <el-input v-model="addInfo.place" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="设备编号:" label-width="100px">
          <el-input v-model="addInfo.equip_no" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="电表ID:" label-width="100px">
          <el-input
            v-model="addInfo.electricity_meter_id"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述:" label-width="100px">
          <el-input type="textarea" v-model="addInfo.description"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFacility()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 副 菜单
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },

      // 筛选 type select中的 列表数据
      typeList: [],
      // 查询参数对象
      queryInfo: {
        name: "",
        organize_id: "",
        type: "",
        page: 1,
        limit: 10,
      },
      // 表格列表
      tableList: [],
      // 总数据条数
      total: 0,

      // 添加设备
      addInfo: {
        id: "",
        organize_id: "",
        name: "",
        type: "",
        place: "",
        equip_no: "",
        electricity_meter_id: "",
        description: "",
      },
      // 添加修改弹出层标题
      addPopupsTitle: "",
      // 弹出层开启与隐藏
      dialogFormVisible: false,
      // 添加中 项目列表
      organizeList: [],
    };
  },
  mounted() {
    // 获取左侧二级菜单的数据
    this.getTreeTitleList();
    // 获取表格列表数据
    this.getTableList();
    // 获取 筛选类型选择项列表
    this.getTypeList();
    // 页面初始化 获取 添加数据中的 组织项目列表
    this.getOrganizeList();
  },
  methods: {
    // 获取左侧二级菜单的数据
    async getTreeTitleList() {
      const res = await this.$http.get("Organize");
      console.log(res);
      if (res.code !== 1) return this.$message.error("获取组织列表失败！");
      // this.$message.success('获取设备列表成功！')
      this.treeData = res.data;
    },
    // 获取 表格列表数据
    async getTableList() {
      const res = await this.$http.post("device", this.queryInfo);
      if (res.code !== 1) return this.$message.error("获取设备列表失败！");
      // this.$message.success('获取设备列表成功！')
      this.tableList = res.data.list;
      this.total = res.data.total;
    },

    // 分页切换 获取列表数据
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getTableList();
    },

    // 获取 筛选select中类型数据
    async getTypeList() {
      const res = await this.$http.post("other/device_type");
      this.typeList = res.data;
    },

    // 筛选查询
    getFiltrateList() {
      this.total = 0;
      this.queryInfo.page = 1;
      this.getTableList();
    },

    // 获取项目组织列表
    async getOrganizeList() {
      const res = await this.$http.post("Organize");
      this.organizeList = res.data;
    },

    // 获取 设备详情
    async getFacilityDetail(id) {
      const res = await this.$http.post("device/info", { device_id: id });
      if (res.code !== 1) return this.$message.error("设备数据获取失败！");
      this.addInfo.id = id;
      this.addInfo.organize_id = res.data.organize_id;
      this.addInfo.name = res.data.name;
      this.addInfo.type = res.data.type;
      this.addInfo.place = res.data.place;
      this.addInfo.equip_no = res.data.equip_no;
      this.addInfo.description = res.data.description;
      this.addInfo.electricity_meter_id = res.data.electricity_meter_id;
    },

    // 打开 添加/修改 设备数据
    handlePopups(id) {
      if (id == undefined) {
        this.addPopupsTitle = "添加设备";
      } else {
        this.addPopupsTitle = "修改设备";
        this.getFacilityDetail(id);
      }
      this.dialogFormVisible = true;
    },

    // 选择 组织/所属项目
    getProjectVal(e) {
      // 清除选择 修改 id为空
      if (e.length == 0) return (this.addInfo.organize_id = "");
      console.log(e, "获取 选中后的最后一个值");
      // console.log(e.slice(-1)[0], "获取 选中后的最后一个值")
      // 获取 选中后的最后一个值
      this.addInfo.organize_id = e.slice(-1)[0];
    },

    // 提交添加设备数据
    async addFacility() {
      if (this.addInfo.organize_id == "")
        return this.$message.error("请选择所属项目！");
      if (this.addInfo.type == "")
        return this.$message.error("请选择用电类型！");
      if (this.addInfo.name == "")
        return this.$message.error("请输入设备名称！");
      if (this.addInfo.place == "")
        return this.$message.error("请输入安装位置！");
      if (this.addInfo.equip_no == "")
        return this.$message.error("请输入设备编号！");
      if (this.addInfo.electricity_meter_id == "")
        return this.$message.error("请输入电表ID！");

      const res = await this.$http.post("device/handle", this.addInfo);
      if (res.code !== 1) return this.$message.error("设备添加失败！");
      this.$message.success("设备添加成功！");
      // 设备添加成功后要更新表格数据
      this.getTableList();
      this.dialogFormVisible = false;
    },

    // 左侧二级菜单
    handleNodeClick(data) {
      console.log(data, "左侧二级菜单");
      if (data.id) {
        this.queryInfo.organize_id = data.id;
        this.getFiltrateList();
      }
    },

    // 删除
    handleDelete(id) {
      this.$confirm("您确定要删除此账号吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$http.post("device/del", { device_id: id });
          if (res.code !== 1) return this.$message.error("设备删除失败！");
          this.$message.success("设备删除成功！");
          // 设备添加成功后要更新表格数据
          this.getTableList();
        })
        .catch((e) => {
          if (e == "cancel") return this.$message.info("已取消删除");
        });
    },

    // 导出
    operDerive() {
      window.location.href =
        "http://211.149.176.35:9001/api/exports/device?organize_id=" +
        this.queryInfo.organize_id +
        "&name=" +
        this.queryInfo.name +
        "&type=" +
        this.queryInfo.type;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: #68d8fe;
  color: #fff;
}
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
// 副菜单
.inside-nav {
  width: 200px;
  height: calc(100% - 40px);
  margin-top: 20px;
  box-sizing: border-box;
  border: #61cdf1 2px solid;
  background-color: #1b284d;
  overflow-y: auto;
}
/* 左侧二级菜单滚动条 设置滚动条 */
.inside-nav::-webkit-scrollbar {
  width: 4px;
}
.inside-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #68d8fe;
}
.inside-nav::-webkit-scrollbar-track {
  border-radius: 0;
  background: #1b284d;
}
/* 设置滚动条  end */

.el-tree {
  background-color: #1b284d;
  color: #ffffff;
}
::v-deep .el-tree-node__label {
  font-size: 14px;
}
::v-deep .el-tree-node__content {
  height: 30px;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #1b284d;
  color: #68d8fe;
}
::v-deep .el-tree-node__content:hover,
::v-deep .el-upload-list__item:hover {
  background-color: #1b284d;
  color: #68d8fe;
}

// 表格
.task_box {
  width: calc(100% - 220px);
  // 顶部按钮
  .oper_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btns {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #ffffff;
      margin-left: 25px;
      background-color: #68d8fe;
      border-radius: 5px;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      cursor: pointer;
      .el-icon {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
  // select 筛选
  .select_box {
    display: flex;
    align-items: center;
    padding: 15px;
    .s_item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
    .btns {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #68d8fe;
      font-size: 16px;
      color: #ffffff;
      width: 80px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
.table_boxs::v-deep {
  margin-top: 20px;
  // padding: 20px 0;
  position: relative;

  // tab表格样式
  .el-table {
    width: 100%;
  }

  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #32477d;
  }
  .el-table tr {
    background-color: #121d3c;
  }
  .el-table {
    color: #ffffff;
  }
  .el-table thead {
    color: #68d8fe;
  }
  .el-tabs__content {
    overflow: unset !important;
  }
  .el-table-column--selection {
    padding: 16px 10px !important;
  }
  .el-table .has-gutter .el-table__cell,
  .el-table th.el-table__cell {
    background-color: #213058;
  }
  .el-table .el-table__cell {
    // padding: 16px 0;
    border: 0;
  }
  .el-table::before,
  .el-table__fixed-right::before {
    height: 0;
  }
  tr.el-table__row--striped td.el-table__cell {
    background-color: #213058;
  }
  .handle_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .handle_btn {
      height: 26px;
      line-height: 26px;
      width: 50px;
      border-radius: 5px;
      border: 1px solid #61cdf1;
      font-size: 13px;
      cursor: pointer;
    }
    .edit {
      background-color: #61cdf1;
    }
  }
  // 分页
  .el-pagination {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-pager li {
      font-size: 12px;
      border: 1px solid #61cdf1;
      font-weight: 500;
      color: #ffffff;
      background-color: #1b284d;
      padding: 0;
      height: 32px;
      line-height: 32px;
      min-width: 32px;
      margin: 0 7px;
    }
    .btn-next,
    .btn-prev {
      border: 1px solid #61cdf1;
      font-weight: 500;
      color: #ffffff;
      background-color: #1b284d;
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
    }
    .btn-next span,
    .btn-prev span {
      font-size: 12px;
      height: 32px;
      line-height: 32px;
    }
  }
}
.el-form .el-select,
.el-form .el-cascader {
  width: 100%;
}
</style>
